import Parse from "parse";

export const getAllPhotos = () => {
    const Photos = Parse.Object.extend("Photos");
    const query = new Parse.Query(Photos);
    return query.find().then((results) => {
        console.log(results);
        return results;
    });
};
