import { useEffect, useState } from "react";
import { getAllPhotos } from "../../../Services/PhotoGalleryService";
import "./Gallery.css";

function Gallery() {
    const [photos, setGallery] = useState([]);
    const [error, setError] = useState(true);
    useEffect(() => {
        getAllPhotos().then((results) => {
            if (results.length > 0) {
                setGallery(results);
                setError(false);
            } else {
                setError(true);
            }
        });
    }, []);

    if (error === true) {
        return (
            <div className="error-container">
                <div className="error-loading">
                    <h1>Whoops!</h1>
                    <h2>Looks like my images aren't loading right now</h2>
                    <h3>
                        Please refresh the page or contact me for further
                        inquiries
                    </h3>
                </div>
            </div>
        );
    }
    return (
        <div className="gallery-container">
            {photos.map((photo) => {
                return (
                    <div key={photo.id} className="gallery-image">
                        <img
                            alt={photo.get("alt")}
                            src={photo.get("image")._url}
                        ></img>
                    </div>
                );
            })}
        </div>
    );
}

export default Gallery;
