import "./Header.css";

function Header() {
    return (
        <div className="header-container">
            <div className="header-card">
                <div className="header-titles">
                    <h1>Paul Maze/Phototech Studios</h1>
                    <h2>In Business For Over 40 Years</h2>
                    <h3>
                        Offering services related to photo re-touching and
                        restorations
                    </h3>
                </div>
                <div className="header-contact">
                    <div className="contact-method">
                        <i id="phone-icon" className="fa fa-phone icon"></i>
                        <span>716-842-1750</span>
                    </div>
                    <div className="contact-method">
                        <i id="mail-icon" className="fa fa-envelope"></i>
                        <span>paulmaze@roadrunner.com</span>
                    </div>
                    <div className="contact-method">
                        <i id="pin-icon" className="fa fa-map-pin"></i>
                        <span>Buffalo, NY 14209</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
