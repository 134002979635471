import "./Body.css";
import Gallery from "./Gallery/Gallery";

function Body() {
    return (
        <div className="body-container">
            <Gallery />
        </div>
    );
}

export default Body;
