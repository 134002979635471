import Body from "../Body/Body";
import Header from "../Header/Header";

import "./Landing.css";

function Landing() {
    return (
        <div className="landing-container">
            <div className="landing-grid">
                <Header />
                <Body />
            </div>
        </div>
    );
}

export default Landing;
