import Components from "./Components/Components";
import * as Env from "./enviroments";
import Parse from "parse";
//Initialize Parse with back4app
Parse.initialize(Env.APPLICATION_ID, Env.JAVASCRIPT_KEY);
Parse.serverURL = Env.SERVER_URL;

function App() {
    return (
        <div>
            <Components />
        </div>
    );
}

export default App;
